










import { Component, Mixins, Vue } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LoginMethods from '@/components/organisms/LoginMethods.vue'

@Component({
  components: {
    InputWithLabel,
    ButtonBase,
  },
})
export default class Login extends Mixins(LoginMethods) {
  private gdlsCode = ''

  private created() {
    this.mode = this.MODE.ACADEMY
  }

  private async submitLogin() {
    this.login(this.gdlsCode)
  }

  private toggleBodyClass(addRemoveClass: string, className: string): void {
    const el = document.body

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  private mounted() {
    this.toggleBodyClass('addClass', 'academy')
    Vue.prototype.$gdlsCookiesV3.setV3(true)
    this.$router.push('/academy/g-method/login')
  }

  private destroyed(): void {
    this.toggleBodyClass('removeClass', 'academy')
  }
}
